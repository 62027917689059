<template>
  <div class="mb-5">
    <hs-icon icon="check-circle" size="55" class="mt-lg-5 text-success" />
    <h3 class="lh-125 font-weight-bold my-4">
      {{ $t('unsubscribe.finish.title') }}
    </h3>
    <p
      class="cancellation-confirmation lh-150 mt-1 mb-5 pt-2"
      v-html="$t('unsubscribe.finish.confirmation', { email: email })"
    />
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.cancellation-confirmation {
  color: $grey-30;
}
</style>
