<template>
  <div class="d-flex w-100 flex-column wrapper mobile-image">
    <figure class="mt-4 mt-md-5 ml-3 mb-5">
      <hs-logo height="25" color="#FF305C" />
    </figure>
    <b-tabs v-if="isAvailable" v-model="tabIndex" nav-class="d-none" nav-wrapper-class="d-none" class="px-3 px-md-2">
      <b-tab v-for="tab in tabs" :key="tab.id">
        <component
          v-bind:is="tab.component"
          :email="email"
          :funnel-id="funnelId"
          :school-id="schoolId"
          @proceed="tabIndex++"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BrowserHelper from '@/shared/helpers/browser';
import HsLogo from '@/components/Logo';
import Confirm from './Confirm';
import Finish from './Finish';

export default {
  components: { HsLogo },
  data() {
    return {
      tabIndex: 0,
      email: null,
      funnelId: null,
      schoolId: null,
    };
  },
  computed: {
    isAvailable() {
      return this.email && (this.funnelId || this.schoolId);
    },
    tabs() {
      return [
        {
          id: 1,
          component: Confirm,
        },
        {
          id: 2,
          component: Finish,
        },
      ];
    },
  },
  methods: {
    checkUrl() {
      this.email = BrowserHelper.getUrlParam('email')
        ? BrowserHelper.getUrlParam('email').replace(/ /g, '+')
        : undefined;
      this.funnelId = BrowserHelper.getUrlParam('funnel_id');
      this.schoolId = BrowserHelper.getUrlParam('school_id');
      if (this.isAvailable) {
        window.history.pushState({}, document.title, window.location.pathname);
        return;
      }
      this.$router.push({ name: 'Login' });
    },
  },
  created() {
    this.checkUrl();
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  background-image: url('~@/assets/images/commons/space_original.svg');
  background-repeat: no-repeat;
  background-size: 80%;
  &.mobile-image {
    @media only screen and (max-width: 375px) {
      background-position: 180% 110% !important;
    }

    @media only screen and (min-width: 375px) and (max-width: $screen-bs-sm) {
      background-position: 180% 120% !important;
    }

    @media only screen and (min-width: 425px) and (max-width: $screen-bs-sm) {
      background-position: 190% 120% !important;
    }

    @media only screen and (min-width: $screen-bs-sm) and (max-width: $screen-bs-md) {
      background-position: 150% 170%;
      background-size: 70%;

      @media screen and (orientation: landscape) {
        background-position: 120% 100% !important;
        background-size: 40% !important;
      }
    }
  }

  @media only screen and (min-width: $screen-bs-md) {
    background-position: 130% 0%;
    background-size: 60%;
  }

  @media only screen and (min-width: $screen-bs-lg) {
    background-position: 110% -15%;
    background-size: 40%;
  }

  @media only screen and (min-width: $screen-bs-xl) {
    background-position: 105% -30%;
    background-size: 40%;
  }
}

.wrapper > * {
  @media only screen and (min-width: $screen-bs-sm) {
    max-width: 75%;
  }

  @media only screen and (min-width: $screen-bs-md) {
    max-width: 50%;
  }

  @media only screen and (min-width: $screen-bs-lg) {
    max-width: 450px;
    margin-left: 120px;
    padding-left: 120px;
  }
}
</style>
