<template>
  <div class="mt-lg-5 mb-5">
    <hs-loading v-if="isLoading" />
    <template v-else>
      <h3 class="title lh-125 font-weight-bold">
        {{ $t(`${prefix}.title`) }}
      </h3>
      <p class="subtitle lh-150 mt-1 mb-5 pt-2">
        {{ $t(`${prefix}.subtitle`, { email: email }) }}
      </p>
      <div class="d-flex flex-column-reverse flex-md-row">
        <hs-button class="mb-2 mb-md-0" variant="primary" @click="unsubscribe">
          {{ $t(`${prefix}.confirm`) }}
        </hs-button>
      </div>
    </template>
  </div>
</template>

<script>
import hsLoading from '@/components/Loading';
import LeadService from '@/sparkfunnels/services/lead';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';

export default {
  props: {
    email: { type: String, required: true },
    funnelId: { type: [String, Number], required: false },
    schoolId: { type: [String, Number], required: false },
  },
  components: { hsLoading },
  computed: { prefix: () => 'unsubscribe.list_selection' },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    processUnsubscribePromisse(promisse) {
      promisse
        .then(() => {
          this.$emit('proceed');
          TrackingHelper.trackLeadsRemoval(1, true);
        })
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.unsubscribe_callback_error`)))
        .finally(() => (this.isLoading = false));
    },
    unsubscribe() {
      this.isLoading = true;
      if (this.schoolId) {
        this.processUnsubscribePromisse(
          LeadService.unsubscribeFromCampaign({ email: this.email, schoolId: this.schoolId })
        );
      } else {
        this.processUnsubscribePromisse(
          LeadService.unsubscribeFromFunnel({ email: this.email, funnelId: this.funnelId })
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  mix-blend-mode: normal;
}

.subtitle {
  color: $grey-30;
}
</style>
